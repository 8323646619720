/*
 * @Author: dongping 3065975706@qq.com
 * @Date: 2022-06-27 09:53:41
 * @LastEditors: dongping 3065975706@qq.com
 * @LastEditTime: 2022-08-15 10:00:48
 * @FilePath: \blog-admind:\NEW-MY-PROJECT\my-doc\src\http\intercept.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// import statusCode from './statusCode.js'
import Vue from "vue";
import { Message } from "element-ui";
import store from "../store/index.js";
import router from "../router/index.js";
const intercept = (res) => {
  if (res.state == 401) {
    // 还未登入
    isLogin(res);
  } else if (res.state == 403) {
    // 登入已过期
    overdue(res);
  } else if (res.state == 500) {
    // 请求错误
    networkError(res);
  } else {
    Message.error(res.message);
  }
};
//   您还未登入
const isLogin = () => {
  Message.error("您还未登入");
  setTimeout(() => {
    router.replace("/login");
  }, 500);
};
//   登入过期
const overdue = () => {
  store.commit("setToken", "");
  store.commit("setUserInfo", "");
  Message.error("登入已经过期");
  setTimeout(() => {
    router.replace("/login");
  }, 500);
};
//   请求错误
const networkError = (res) => {
  Message.error(res.message);
};
export default intercept;
