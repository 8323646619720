<template>
  <div class="mavon-editor">
    <!--markdown编辑 -->
    <mavon-editor
      codeStyle="atom-one-dark"
      @save="save"
      @change="onMarkdownValue"
      @imgAdd="upImage"
      :imageClick="imageClick"
      :ishljs="true"
      ref="mavon-md"
      :toolbars="toolbars"
      v-model="markdownValue"
      boxShadowStyle="1px 1px 5px 1px #f5f5ff"
    />
  </div>
</template>
<script>
import { mavonEditor } from "mavon-editor";
import "mavon-editor/dist/css/index.css";

export default {
  props: {
    value: "",
  },
  components: {
    mavonEditor,
  },
  data() {
    return {
      markdownValue: "",
      htmlValue: "",
      toolbars: {
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: true, // 标记
        superscript: false, // 上角标
        subscript: false, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        imagelink: true, // 图片链接
        code: true, // code
        table: true, // 表格
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        help: true, // 帮助
        /* 1.3.5 */
        undo: true, // 上一步
        redo: true, // 下一步
        trash: true, // 清空
        save: true, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: true, // 导航目录
        /* 2.1.8 */
        alignleft: true, // 左对齐
        aligncenter: true, // 居中
        alignright: true, // 右对齐
        /* 2.2.1 */
        subfield: false, // 单双栏模式
        preview: true, // 预览
      },

      imageClick: (value) => {
        console.log(value.src);
        this.$emit("previewImg", value.src);
      },
    };
  },
  mounted() {
    this.markdownValue = this.value;
  },
  watch: {
    value(value) {
      this.markdownValue = value;
    },
  },
  methods: {
    onMarkdownValue(value) {
      this.$emit("change", {
        htmlValue: this.$refs["mavon-md"].d_render,
        markdownValue: this.markdownValue,
      });
    },
    save(value) {
      this.$emit("save", {
        htmlValue: this.$refs["mavon-md"].d_render,
        markdownValue: this.markdownValue,
      });
    },
    // 图片上传
    upImage(pos, file) {
      this.$upImg(file)
        .then((res) => {
          this.$refs["mavon-md"].$img2Url(pos, res.data);
        })
        .catch((err) => {
          console.log(res, "图片上传失败");
        });
    },
  },
};
</script>
<style lang="scss">
.mavon-editor {
  height: 100%;
}
.v-note-wrapper.shadow {
  height: 100%;
}
.v-note-wrapper
  .v-note-panel
  .v-note-edit.divarea-wrapper
  .content-input-wrapper {
  padding: 10px 0 !important;
}
.mavon-editor * {
  letter-spacing: 1.5px;
  line-height: 1.5em !important;
  -webkit-text-size-adjust: 100%;
  word-break: break-all;
  text-align: justify;
}

// 侧边行号 - 圆点
.v-note-show pre {
  margin: 10px 0 !important;
  border-radius: 5px !important;
  padding: 40px 0 20px 20px !important;
  position: relative !important;
  margin-bottom: 24px !important;
  background: #323641 !important;
  overflow: hidden;
  font-size: 14px;
  position: relative;
  &::before {
    content: " ";
    position: absolute;
    border-radius: 50%;
    background: #ff5f56;
    width: 10px;
    height: 10px;
    top: 0;
    left: 10px;
    margin-top: 12px;
    -webkit-box-shadow: 20px 0 #ffbd2e, 40px 0 #27c93f;
    box-shadow: 20px 0 #ffbd2e, 40px 0 #27c93f;
    z-index: 3;
  }
  &::after {
    width: 100%;
    height: 30px;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    background: #222832;
  }
}
.v-note-show pre code {
  font-size: 1.1em !important;
  line-height: 0.16em !important;
}
.v-note-show pre * {
  line-height: 18px;
}

.v-note-show .pre-numbering {
  position: absolute;
  top: 20px;
  left: 0;
  width: 20px;
  height: 100%;
  padding: 22px 8px 12px 5px;
  border-right: 1px solid #aaa;
  background-color: #323641;
  text-align: right;
}
.v-note-show .pre-numbering li {
  list-style: none;
  font-family: Menlo, monospace;
  color: #aaa;
  margin: 0;
}
.v-note-show .hljs {
  background-color: #323641;
}

// 表格
.v-note-show tr,
.v-note-show td,
.v-note-show table,
.v-note-show th {
  // border: 1px solid #dfe2e5;
  padding: 6px 13px;
  // table-layout: fixed;
}

.v-note-show table {
  overflow: auto;
  border-spacing: 0;
  border-collapse: collapse;
}
.v-note-show table tr:nth-child(2n) {
  background: #f6f8fa;
}

// code样式
.v-note-show code {
  background: rgba(27, 31, 35, 0.05);
  padding: 2px 4px;
  margin: 0;
  font-size: 85%;
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
}

// 设置 ul li
// .v-note-show ul  {
//   padding-left: 15px;
// }
.v-note-show li {
  margin-left: 30px;
}
.v-note-show ul li {
  list-style-type: disc;
}
.v-note-show ol li {
  list-style-type: decimal;
}
// 设置 水平线样式
.v-note-show hr {
  height: 4px;
  padding: 0;
  margin: 24px 0;
  background-color: #e1e4e8;
  border: 0;
}

.v-note-show a {
  text-decoration: none;
  color: #91b1ff !important;
  font-weight: 600;
  margin: 0 3px;
  &:hover {
    text-decoration: underline;
    color: #91b1ff;
  }
}

.v-note-show p {
  color: #24292e;
}

.v-note-show p a {
  text-decoration: none;
  color: #91b1ff !important;
  font-weight: 600;
  margin: 0 3px;
  &::before {
    content: "转到 : ";
  }
  &:hover {
    text-decoration: underline;
    color: #91b1ff;
  }
}

.v-note-show img {
  max-height: 200px;
  width: auto;
}

.v-note-show strong {
  font-weight: bold;
}
.v-note-show em {
  font-style: italic;
}

.v-note-show blockquote {
  padding: 10px 20px !important;
  position: relative;
  margin: 10px 5px;
  background: #f2f7fb;
  &::before {
    content: "";
    width: 5px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #dce6f0;
  }
}

.v-note-show mark {
  background: #91b1ff;
  padding: 0 3px;
}

.v-note-show * {
  letter-spacing: 1.5px;
  line-height: 1.5em;
  -webkit-text-size-adjust: 100%;
  word-break: break-all;
  text-align: justify;
  // font-family: '-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';
  // font-family: 'Consolas, "Courier New", monospace';
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !important;
}

.v-note-show h1,
.v-note-show h2,
.v-note-show h3,
.v-note-show h4,
.v-note-show h5,
.v-note-show h6 {
  padding: 15px 0;
  border-bottom: 1px solid #eaecef;
  margin: 10px 0;
}
// 对齐方式
.v-note-show .hljs-center {
  text-align: center;
}
.v-note-show .hljs-left {
  text-align: left;
}
.v-note-show .hljs-right {
  text-align: right;
}
</style>
