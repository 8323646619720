/*
 * @Author: dongping 3065975706@qq.com
 * @Date: 2022-08-14 16:32:51
 * @LastEditors: dongping 3065975706@qq.com
 * @LastEditTime: 2022-08-15 11:35:10
 * @FilePath: \blog-admind:\my-project\generative-debt-admin\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import mutations from "./mutations";
import actions from "./actions";
import modules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules,
});
