/*
 * @Author: dongping 3065975706@qq.com
 * @Date: 2022-06-27 09:53:41
 * @LastEditors: dongping 3065975706@qq.com
 * @LastEditTime: 2022-08-14 22:27:25
 * @FilePath: \blog-admind:\NEW-MY-PROJECT\my-doc\src\http-api\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import publics from "./publics";
import orders from "./orders";
import customer from "./customer";
import goods from "./goods";
export default {
  ...publics,
  ...orders,
  ...customer,
  ...goods
};
