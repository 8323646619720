<template>
  <div class="markdown-edit-index">
    <markdown-edit
      @change="onMarkdownValue"
      @save="save"
      :value="value"
    ></markdown-edit>
  </div>
</template>

<script>
import markdownEdit from "./src/markdown-edit";
export default {
  props: {
    value: {
      default: "",
    },
  },
  name: "markdown-highlight-main",
  components: {
    markdownEdit,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onMarkdownValue(value) {
      this.$emit("change", {
        htmlValue: value.htmlValue,
        markdownValue: value.markdownValue,
      });
    },
    save(value) {
      this.$emit("save", {
        htmlValue: value.htmlValue,
        markdownValue: value.markdownValue,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.markdown-edit-index {
  height: 100%;
}
</style>