/*
 * @Author: dongping 3065975706@qq.com
 * @Date: 2022-06-27 09:53:41
 * @LastEditors: dongping 3065975706@qq.com
 * @LastEditTime: 2022-08-15 10:59:59
 * @FilePath: \blog-admind:\NEW-MY-PROJECT\my-doc\src\store\state.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  // 用户Token
  userToken: (() => {
    if (localStorage.getItem("userToken")) {
      return JSON.parse(localStorage.getItem("userToken"));
    } else {
      return "";
    }
  })(),
  // 用户信息
  userInfo: (() => {
    if (localStorage.getItem("userInfo")) {
      return JSON.parse(localStorage.getItem("userInfo"));
    } else {
      return "";
    }
  })(),
  // 用户手机号码
  userPhone: (() => {
    if (localStorage.getItem("userPhone")) {
      return JSON.parse(localStorage.getItem("userPhone"));
    } else {
      return "";
    }
  })(),
  // 当前菜单
  currentMenu: (() => {
    if (localStorage.getItem("currentMenu")) {
      return JSON.parse(localStorage.getItem("currentMenu"));
    } else {
      return 0;
    }
  })(),
};
