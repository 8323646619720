/*
 * @Author: dongping 3065975706@qq.com
 * @Date: 2022-06-27 09:53:41
 * @LastEditors: dongping 3065975706@qq.com
 * @LastEditTime: 2022-08-17 15:12:56
 * @FilePath: \blog-admind:\NEW-MY-PROJECT\my-doc\src\http\axios.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import axios from "axios";
import httApi from "../http-api/index";
import { BASEURL } from "../config/env";
import intercept from "./intercept";
import store from "../store/index";

const instance = axios.create();
instance.defaults.baseURL = BASEURL;
// 请求类型
let contentType = "application/json";
// 请求拦截
instance.interceptors.request.use(
  (config) => {
    if (store.state.userToken) {
      config.headers = {
        token: store.state.userToken,
        "Content-Type": contentType,
      };
    }
    return config;
  },
  (error) => {
    // console.log('拦截失败', error)
    return Promise.error(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  (res) => {
    if (res.data.code !== 200) {
      // Message.error(res.data.message);
    }
    return res.data;
  },
  // 请求失败
  (err) => {
    console.log(err.response, "err");
    if (err.response) {
      let data = err.response.data;
      intercept(data);
      return Promise.reject(err.response.data);
    } else {
      return Promise.reject(err);
    }
  }
);

// 请求方法
const $http = (url, params, config = {}) => {
  let https = httApi[url];
  let method = https.method.toLocaleLowerCase();

  //   设置请求头
  let header = {};
  if (config.hasOwnProperty("contentType")) contentType = config.contentType;

  return new Promise((resolve, reject) => {
    if (method == "get") {
      instance[method](https.url, {
        params,
      })
        .then((res) => {
          if (res.state == 200) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    } else if (method == "post") {
      instance[method](https.url, params)
        .then((res) => {
          if (res.state == 200) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};

Vue.prototype.$http = $http;
export default {
  $http,
  instance,
};
