/*
 * @Author: dongping 3065975706@qq.com
 * @Date: 2022-08-14 16:32:43
 * @LastEditors: dongping 3065975706@qq.com
 * @LastEditTime: 2022-08-14 20:44:38
 * @FilePath: \blog-admind:\my-project\generative-debt-admin\src\common\function.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 常用的方法
import Vue from "vue";
const check = {
  //   获取时间
  $getDate(d) {
    let date = d ? new Date(d) : new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    let week = date.getDay();

    return {
      year,
      month,
      day,
      hour,
      minute,
      second,
      week,
    };
  },
};

for (let key in check) {
  Vue.prototype[key] = check[key];
}
