<template>
  <div class="cropper-img">
    <div class="vue-cropper-main">
      <div class="vue-cropper-main-left">
        <vue-cropper
          ref="cropper"
          :img="options.img"
          :info="true"
          :canScale="options.canScale"
          :autoCrop="options.autoCrop"
          :mode="options.mode"
          :enlarge="options.enlarge"
          :maxImgSize="options.maxImgSize"
          :infoTrue="options.infoTrue"
          :height="options.height"
          :centerBox="options.centerBox"
          :original="options.original"
          :canMove="options.canMove"
          :fixedBox="options.fixedBox"
          :full="options.full"
          :fixedNumber="options.fixedNumber"
          :fixed="options.fixed"
          :autoCropHeight="options.autoCropHeight"
          :autoCropWidth="options.autoCropWidth"
          :outputType="options.outputType"
          :outputSize="options.outputSize"
          :canMoveBox="options.canMoveBox"
          @realTime="realTime"
        />
      </div>
      <div class="vue-cropper-main-right">
        <!-- <div class="circular">
          <img :style="preView.style" :src="preView.img" alt="" />
        </div> -->
        <div class="square">
          <img :style="preView.style" :src="preView.img" alt="" />
        </div>
      </div>
    </div>
    <div class="vue-cropper-operation">
      <span>
        <input
          class="file"
          type="file"
          @change="onSelectImg"
          accept="image/*"
        />
        选择图片
      </span>
      <span @click="changeScale(1)">放大</span>
      <span @click="changeScale(-1)">缩小</span>
      <span @click="rotateLeft()">向左旋转</span>
      <span @click="rotateRight()">向右旋转</span>
      <!-- <span @click="uploadImg()">确认</span> -->
    </div>
  </div>
</template>
<script>
import VueCropper from "./vue-cropper.vue";
export default {
  name: "WorkspaceJsonCropperImg",
  props: {
    src: {
      default: "",
    },
  },
  components: {
    VueCropper,
  },
  data() {
    return {
      options: {
        img: "",
        outputSize: 1, //裁剪生成图片的质量(可选0.1 - 1)
        outputType: "jpeg", //裁剪生成图片的格式（jpeg || png || webp）
        info: true, //图片大小信息
        canScale: true, //图片是否允许滚轮缩放
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 200, //默认生成截图框宽度
        autoCropHeight: 200, //默认生成截图框高度
        fixed: true, //是否开启截图框宽高固定比例
        // fixedNumber: [1.53, 1], //截图框的宽高比例
        full: false, //false按原比例裁切图片，不失真
        fixedBox: true, //固定截图框大小，不允许改变
        canMove: true, //上传图片是否可以移动
        canMoveBox: true, //截图框能否拖动
        original: false, //上传图片按照原始比例渲染
        centerBox: false, //截图框是否被限制在图片里面
        height: true, //是否按照设备的dpr 输出等比例图片
        infoTrue: false, //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000, //限制图片最大宽度和高度
        enlarge: 1, //图片根据截图框输出比例倍数
        mode: "280px 200px", //图片默认渲染方式
      },
      preView: {
        img: "",
        style: {},
      },
    };
  },

  mounted() {
    this.options.img = this.src;
  },
  watch: {
    src() {
      this.options.img = this.src;
    },
  },
  methods: {
    // 选择图片
    onSelectImg(data) {
      if (data.target.files.length <= 0) return;
      let file = data.target.files[0];
      if (window.FileReader) {
        let that = this;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        //监听文件读取结束后事件
        reader.onloadend = function (e) {
          that.options.img = e.target.result;
        };
      }
    },
    // 预览
    realTime(data) {
      this.preView.img = data.url;
      this.preView.style = data.img;
    },
    //   覆盖默认上传行为
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    //确认裁剪
    confirmCutting() {
      let _this = this;
      return new Promise((resolve, reject) => {
        this.$refs.cropper.getCropBlob(async (data) => {
          console.log(data);
          let formData = new FormData();
          formData.append("file", data, "img.jpg");
          let res = {
            blob: data,
            formData: formData,
          };
          this.$emit("success", res);
          resolve(res);
        });
      });
    },
    requestUpload() {},
  },
};
</script>

<style lang="scss" scoped>
.cropper-img {
}
.vue-cropper-main {
  display: flex;
  padding: 20px;
}
.vue-cropper-main .vue-cropper-main-left {
  height: 200px;
  width: 500px;
}
.vue-cropper-main .vue-cropper-main-right {
  width: 200px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vue-cropper-main .vue-cropper-main-right .circular {
  width: 200px;
  height: 200px;
  background: #eee;
  border-radius: 50%;
  margin-bottom: 30px;
  overflow: hidden;
}
.vue-cropper-main .vue-cropper-main-right .square {
  width: 200px;
  height: 200px;
  background: #eee;
  overflow: hidden;
}

// 操作
.vue-cropper-operation {
  padding: 0 20px;
}
.vue-cropper-operation span {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 5px;
  border: 1px solid #eee;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  user-select: none;
  background: #91b1ff;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.vue-cropper-operation span:hover {
  opacity: 0.8;
}

.vue-cropper-operation .file {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}
</style>