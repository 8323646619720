import modal from "./modal/index.vue";
import markdownEdit from "./markdown-edit/index.vue";
import uploadImg from "./upload-img/upload-img.vue";

const components = {
  install: function (Vue) {
    Vue.component("modal", modal);
    Vue.component("markdownEdit", markdownEdit);
    Vue.component("uploadImg", uploadImg);
  },
};

export default components;
