/*
 * @Author: dongping 3065975706@qq.com
 * @Date: 2022-06-27 09:53:41
 * @LastEditors: dongping 3065975706@qq.com
 * @LastEditTime: 2022-08-15 12:28:36
 * @FilePath: \blog-admind:\NEW-MY-PROJECT\my-doc\src\config\env.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
let BASEURL = "";
if (process.env.NODE_ENV === "development") {
  // 开发环境
  BASEURL = "http://localhost:3003";
  BASEURL = "https://daoguan.u-pin.cn";
} else if (process.env.NODE_ENV === "production") {
  //    生产环境
  BASEURL = "https://daoguan.u-pin.cn";
}

Vue.prototype.$BASEURL = BASEURL;

export { BASEURL };
