/*
 * @Author: dongping 3065975706@qq.com
 * @Date: 2022-06-27 09:53:41
 * @LastEditors: dongping 3065975706@qq.com
 * @LastEditTime: 2022-08-17 15:37:12
 * @FilePath: \blog-admind:\NEW-MY-PROJECT\my-doc\src\config\utils\utils.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  $isEmpty(value) {
    switch (typeof value) {
      case "undefined":
        return true;
      case "string":
        if (value.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, "").length == 0)
          return true;
    }
    return false;
  },
  $downLoadFile(data, fileName) {
    var blob = new Blob([data], {
      // type: "application/msword;chartset=UTF-8", // 通过 buffer流 导出word
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8", // 通过 buffer流 导出 Xlsx
    });
    // 浏览器下载
    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, fileName || "file");
    } else {
      var a = document.createElement("a");
      var body = document.querySelector("body");
      a.href = window.URL.createObjectURL(blob);
      a.style.display = "none";
      body.appendChild(a);
      a.click();
      body.removeChild(a);
      window.URL.revokeObjectURL(a.href);
    }
  },
};
