export default {
    // 获取商品列表
    getGoodsList: {
        url: "/goods/getGoodsList",
        auth: true,
        method: "GET",
    },
    // 获取商品列表
    editGoods: {
        url: "/goods/editGoods",
        auth: true,
        method: "POST",
    },
}