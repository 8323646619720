/*
 * @Author: dongping 3065975706@qq.com
 * @Date: 2022-06-27 09:53:41
 * @LastEditors: dongping 3065975706@qq.com
 * @LastEditTime: 2022-08-17 15:16:09
 * @FilePath: \blog-admind:\NEW-MY-PROJECT\my-doc\src\http-api\home.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  // 获取订单列表
  getOrderAdminListsl: {
    url: "/orders/getOrderAdminListsl",
    auth: true,
    method: "GET",
  },
  // 订单确认退款
  confirmRefund: {
    url: "/orders/confirmRefund",
    auth: true,
    method: "POST",
  },

  // 确认完成所有进行中的订单
  confirmCompleteAlOrder: {
    url: "/orders/confirmCompleteAlOrder",
    auth: true,
    method: "POST",
  },
};
