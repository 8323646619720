import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 常用方法 存放
import common from "./common/index";
// 配置文件
import config from "./config/index";
// 全局组件
import components from "./components/index";

// 网络请求
import http from "./http/index";
// css配置
import "./assets/css/init.css";
// 配置svg
import "./config/icons";
// 配置element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import { ElementTiptapPlugin } from "element-tiptap";
// import ElementUI's styles
import "element-ui/lib/theme-chalk/index.css";
// import this package's styles
import "element-tiptap/lib/index.css";

Vue.use(ElementUI);
Vue.use(ElementTiptapPlugin, {
  /* plugin options */
  lang: "zh", // 设置语言为中文
});
Vue.use(components);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
