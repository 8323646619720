/*
 * @Author: dongping 3065975706@qq.com
 * @Date: 2022-06-27 09:53:41
 * @LastEditors: dongping 3065975706@qq.com
 * @LastEditTime: 2022-08-15 10:59:43
 * @FilePath: \blog-admind:\NEW-MY-PROJECT\my-doc\src\store\mutations.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  // 用户token
  setToken(state, userToken) {
    state.userToken = userToken;
    localStorage.setItem("userToken", JSON.stringify(userToken));
  },
  // 用户信息
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo;
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  },
  //用户手机号
  setPhone(state, value) {
    state.userPhone = value;
    localStorage.setItem("userPhone", JSON.stringify(value));
  },
  // 当前菜单
  currentMenu(state, value) {
    state.currentMenu = value;
    localStorage.setItem("currentMenu", JSON.stringify(value));
  },
};
