<template>
  <div class="uploadImg">
    <div class="uploadImg-list">
      <div @dragover="dragover($event)" @dragend="onEnd()">
        <!-- <transition-group class="transition-wrapper" name="sort"> -->
        <template v-if="selectImg.length > 0">
          <div
            class="select-img-item"
            :class="[
              { 'select-img-itemHidden': itemHidden },
              { 'no-transition': stopAnimation },
            ]"
            :style="{
              width: width,
              height: height,
              backgroundImage: `url(${item.base64})`,
            }"
            v-for="(item, index) in selectImg"
            :key="item.id"
            :draggable="true"
            @dragstart="dragstart(item)"
            @dragenter="dragenter(item, $event)"
            @dragend="dragend(item, $event)"
            @dragover="dragover($event)"
          >
            <!-- <img :src="item.base64" alt="" /> -->
            <div class="operation">
              <!-- <span @click="onPreview(item)">预览</span> -->
              <span @click="delImg(index)">删除</span>
              <!-- <span @click="editImg(item, index)">编辑</span> -->
            </div>
            <div class="bg"></div>
          </div>
        </template>

        <div
          key="9891"
          :class="['uploadImg-list-item', { 'no-transition': stopAnimation }]"
          :style="{ width: width, height: height }"
          v-if="selectImg.length < multiple"
        >
          <div class="uploadImg-icon">
            <i class="el-icon-plus"></i>
            <p>选择图片</p>
          </div>
          <input
            class="file"
            v-if="multiple <= 1"
            type="file"
            @change="onSelectImg"
            accept="image/*"
          />
          <input
            v-if="multiple > 1"
            class="file"
            multiple
            type="file"
            @change="onSelectImg"
            accept="image/*"
          />
        </div>
        <!-- </transition-group> -->
      </div>
    </div>
    <dialog-cropper
      title="编辑图片"
      width="600px"
      :src="editImgSrc"
      v-model="cropperShow"
      @confirm="successImg"
    >
    </dialog-cropper>
  </div>
</template>

<script>
import dialogCropper from "./dialog-cropper/dialog-cropper.vue";
export default {
  name: "upload-img" + Math.random(),
  props: {
    previewImg: {
      default() {
        return [];
      },
    },
    multiple: {
      default: 8,
      type: Number,
    },
    height: {
      default: "110px",
    },
    width: {
      default: "110px",
    },
  },
  components: {
    dialogCropper,
  },
  data() {
    return {
      selectImg: [],
      cropperShow: false,
      editImgSrc: "",
      editImgIndex: null,
      editSrc: "",

      oldData: null, // 开始排序时按住的旧数据
      newData: null, // 拖拽过程的数据
      flag: true, //节流 防止跳动
      itemHidden: true,
      stopAnimation: false,
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    previewImg: {
      deep: true,
      async handler() {
        this.initData();
      },
    },
  },
  methods: {
    initData() {
      let list = [];
      for (let item of this.previewImg) {
        let data = {};
        data.base64 = item.url;
        data.formData = item.url;
        data.file = item.url;
        data.originUrl = item.url;
        list.push(data);
        data.id = Date.now();
      }
      this.selectImg = list;
      this.$emit("change", this.selectImg);
    },
    onSelectImg(data) {
      if (data.target.files.length <= 0) return;
      let imgNum = this.multiple - this.selectImg.length;
      let files = [...data.target.files];
      files = files.slice(0, imgNum);
      this.stopAnimation = true;

      files.forEach((file, index) => {
        if (window.FileReader) {
          let that = this;
          var reader = new FileReader();
          reader.readAsDataURL(file);
          //监听文件读取结束后事件
          reader.onloadend = function (e) {
            // 转blob格式
            let blobImg = that.dataURLtoBlob(reader.result);
            // 转fromdata格式
            let formData = new FormData();
            formData.append("file", blobImg, "img.jpg");
            // 整理数据
            let fileValue = that.base64ToFile(reader.result);

            that.selectImg.push({
              base64: reader.result,
              formData: formData,
              blobImg: blobImg,
              file: fileValue,
              id: Date.now() + "" + index,
            });
            that.$emit("change", that.selectImg);
            setTimeout(() => {
              that.stopAnimation = false;
            }, 100);
          };
        }
      });
    },
    delImg(index) {
      this.stopAnimation = true;
      this.selectImg.splice(index, 1);
      this.$emit("change", this.selectImg);
      this.stopAnimation = false;
    },
    onPreview(item) {
      this.$emit("onPreview", {
        item: item,
        selectImg: this.selectImg,
      });
    },
    editImg(item, index) {
      this.editImgIndex = index;
      this.editImgSrc = item.base64;
      this.cropperShow = true;
      this.$emit("change", this.selectImg);
    },
    successImg(img) {
      this.blobToDataURL(img.blob, (data) => {
        this.editSrc = data;
        this.selectImg[this.editImgIndex].base64 = data;
        this.selectImg[this.editImgIndex].formData = img.formData;
        this.selectImg[this.editImgIndex].file = this.base64ToFile(data);
        this.selectImg[this.editImgIndex].originUrl = "";
        this.$emit("change", this.selectImg);
      });
    },
    // 转 blob格式
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(",");
      //注意base64的最后面中括号和引号是不转译的
      var _arr = arr[1].substring(0, arr[1].length - 2);
      var mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(_arr),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {
        type: mime,
      });
    },
    // 转base64
    blobToDataURL(blob, callback) {
      let fileReade = new FileReader();
      fileReade.onload = function (e) {
        callback(e.target.result);
      };
      fileReade.readAsDataURL(blob);
    },

    // base64 转 file
    base64ToFile(urlData) {
      let arr = urlData.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let type = mime.split("/")[1];

      let typeValue = {
        png: "png",
        jpeg: "jpg",
      };

      let name = Date.now() + "";

      let bytes = atob(arr[1]); // 解码base64
      let n = bytes.length;
      let ia = new Uint8Array(n);
      while (n--) {
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia], `${name}.${typeValue[type]}`, { type: mime });
    },

    // 拖拽
    dragstart(value) {
      this.itemHidden = false;
      this.oldData = value;
    },
    // 记录移动过程中信息
    dragenter(value, e) {
      this.newData = value;
      e.preventDefault();
      if (this.flag) {
        this.dragend();
      }
    },
    // 拖拽最终操作
    dragend(value, e) {
      if (this.oldData !== this.newData) {
        let oldIndex = this.selectImg.indexOf(this.oldData);
        let newIndex = this.selectImg.indexOf(this.newData);
        let newItems = [...this.selectImg];
        // 删除老的节点
        newItems.splice(oldIndex, 1);
        // 在列表中目标位置增加新的节点
        newItems.splice(newIndex, 0, this.oldData);
        this.selectImg = [...newItems];

        this.flag = false;
        setTimeout(() => {
          this.flag = true;
        }, 200);
      }
    },
    // 拖动事件（主要是为了拖动时鼠标光标不变为禁止）
    dragover(e) {
      e.preventDefault();
    },
    onEnd() {
      this.itemHidden = true;
      this.$emit("change", this.selectImg);
    },
  },
};
</script>

<style lang="scss" scoped>
.uploadImg-list {
  display: flex;
  flex-wrap: wrap;
}
.transition-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.uploadImg-list .uploadImg-list-item,
.uploadImg-list .select-img-item {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  background: #fff;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ccc;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.3s;
  background-size: cover;
}
.no-transition {
  transition: none !important;
}

.uploadImg-list .uploadImg-list-item .uploadImg-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.uploadImg-list .uploadImg-list-item i {
  font-size: 30px;
}
.uploadImg-list .uploadImg-list-item p {
  line-height: 15px;
}

// 选中的图片列表
.uploadImg-list .select-img-item {
  cursor: move;
}

.uploadImg-list .select-img-item img {
  object-fit: contain;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

// 操作
.uploadImg-list .select-img-item .operation {
  width: 100%;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  user-select: none;
  text-align: center;
  // display: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.uploadImg-list .select-img-item .operation span {
  margin: 0 5px;
  color: #fff;
}
.uploadImg-list .select-img-item .bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}

// 蒙版 操作
.uploadImg-list .select-img-itemHidden:hover .operation {
  display: block !important;
}
.uploadImg-list .select-img-itemHidden:hover .bg {
  display: block !important;
}

// 选择图片
.uploadImg-list .uploadImg-list-item:hover {
  opacity: 0.5;
}
.uploadImg-list .uploadImg-list-item input {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
}
</style>