/*
 * @Author: dongping 3065975706@qq.com
 * @Date: 2022-08-17 15:12:09
 * @LastEditors: dongping 3065975706@qq.com
 * @LastEditTime: 2022-08-17 15:18:10
 * @FilePath: \blog-admind:\my-project\generative-debt-admin\src\http\download.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import axios from "./axios";
const Api = "/admin/downloadxlsx";
// 下载文件封装
// import $http from './axios'
const $download = (params) => {
  return axios.instance.post(Api, params, {
    responseType: "arraybuffer",
  });
};

Vue.prototype.$download = $download;

export default $download;
