/*
 * @Author: dongping 3065975706@qq.com
 * @Date: 2022-08-14 16:32:43
 * @LastEditors: dongping 3065975706@qq.com
 * @LastEditTime: 2022-08-14 20:44:05
 * @FilePath: \blog-admind:\my-project\generative-debt-admin\src\common\check.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
const check = {
  //只能为数字  是纯数字返回true 否则返回false
  $isNum(value) {
    return /^[0-9]+$/.test(value);
  },

  //6-16位数字和字母组合  符合条件返回true 否则返回false
  $isEnAndNo(value) {
    return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(value);
  },
};

for (let key in check) {
  Vue.prototype[key] = check[key];
}
