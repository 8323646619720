import Vue from "vue";

import env from "./env";
import utils from "./utils/utils";
let config = {
  ...utils,
};

for (let key in config) {
  Vue.prototype[key] = config[key];
}
