import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录",
    },
    component: () => import("../views/publics/login.vue"),
    childrens: [],
  },
  {
    path: "/",
    name: "layout",
    component: () => import("../views/layout/layout.vue"),
    children: [
      {
        path: "/",
        redirect: "/dataTotal",
      },
      {
        path: "/dataTotal",
        name: "dataTotal",
        meta: {
          auth: true,
          title: "数据统计",
        },
        component: () => import("../views/data-total/data-total.vue"),
      },
      {
        path: "/orders",
        name: "orders",
        meta: {
          auth: true,
          title: "订单管理",
        },
        component: () => import("../views/orders/orders.vue"),
      },
      {
        path: "/customer",
        name: "customer",
        meta: {
          auth: true,
          title: "客户管理",
        },
        component: () => import("../views/customer/customer.vue"),
      },
      {
        path: "/goods",
        name: "goods",
        meta: {
          auth: true,
          title: "商品管理",
        },
        component: () => import("../views/dataAdmin/goods.vue"),
      },
      {
        path: "/accountNumberAdmin",
        name: "accountNumberAdmin",
        meta: {
          auth: true,
          title: "账号管理",
        },
        component: () =>
          import("../views/accountNumberAdmin/accountNumberAdmin.vue"),
      },
    ],
  },

  {
    path: "/error",
    name: "error",
    meta: {
      title: "页面不存在",
    },
    component: () => import("../views/publics/error-page.vue"),
    childrens: [],
  },
  {
    path: "*",
    name: "error",
    meta: {
      title: "页面不存在",
    },
    component: () => import("../views/publics/error-page.vue"),
    childrens: [],
  },
];

const router = new VueRouter({
  routes,
});
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 这里就可以进行vue-router的beforeEach拦截了，你也可以放其他地方，我比较喜欢放这
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "";

  if (to.meta.auth) {
    if (store.state.userToken) {
      console.log(from, "to.path");
      if (to.path == "/login") {
        next("/home");
      } else {
        next();
      }
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
