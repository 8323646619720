import Vue from "vue";
import axios from "./axios";
const Api = "/upload/upLoadImage";
// 上传图片

const $upImg = (file) => {
  const params = new FormData();
  params.append("file", file);
  return axios.instance.post(Api, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

Vue.prototype.$upImg = $upImg;

export default $upImg;
