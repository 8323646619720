/*
 * @Author: dongping 3065975706@qq.com
 * @Date: 2022-08-10 10:06:41
 * @LastEditors: dongping 3065975706@qq.com
 * @LastEditTime: 2022-08-14 16:55:00
 * @FilePath: \blog-admind:\NEW-MY-PROJECT\my-doc\src\http-api\publics.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  // 用户登录
  adminLogin: {
    url: "/public/adminLogin",
    auth: true,
    method: "POST",
  },
};
