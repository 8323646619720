<template>
  <div class="modal">
    <div
      @click="bgClose"
      :class="{ 'modal-bg-show': show }"
      class="modal-bg"
    ></div>
    <div
      :style="{
        minWidth: width,
        height: height,
        background: bg,
        borderRadius: radius,
      }"
      :class="{ 'modal-show': show }"
      class="modal-main"
    >
      <slot name="main">
        <slot name="header">
          <div class="modal-title" :style="{ background: headerBg }">
            <span :style="{ color: headerTextColor }">{{ title }}</span>
            <div :style="{ color: headerTextColor }" @click="close">
              <i class="el-icon-circle-close"></i>
            </div>
          </div>
        </slot>
        <slot>
          <cropper ref="cropper" @success="successImg" :src="src"></cropper>
        </slot>
        <slot name="bottom">
          <div class="modal-bottom">
            <button @click="close" type="primary" size="small" plain>
              取消
            </button>
            <button @click="confirm" type="primary" size="small ">确认</button>
          </div>
        </slot>
      </slot>
    </div>
  </div>
</template>

<script>
import cropper from "./config/cropper.vue";
export default {
  name: "modal",
  model: {
    prop: "show",
    event: "input",
  },
  props: {
    src: {
      default: "",
    },
    show: {
      default: false,
    },
    width: {
      default: "30%",
    },
    height: {
      default: "auto",
    },
    bg: {
      default: "#FFF",
    },
    radius: {
      default: "5px",
    },
    title: {
      default: "标题",
    },
    isbgClose: {
      default: true,
    },
    headerBg: {
      default: "#fff",
    },
    headerTextColor: {
      default: "#333",
    },
  },
  components: {
    cropper,
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {
    close(scene = "normal") {
      this.$emit("input", false);
      this.$emit("close", scene);
    },
    bgClose() {
      if (this.isbgClose) {
        this.close("bg");
      }
    },
    confirm() {
      this.$refs.cropper.confirmCutting();
    },
    successImg(imgdata) {
      this.$emit("confirm", imgdata);
      this.close("confirm");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
}
.modal-bg {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 998;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s;
}
.modal-main {
  min-width: 30px;
  min-height: 30px;
  background: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%) scale(0.9);
  z-index: 999;
  transition: all 0.3s;
  opacity: 0;
  pointer-events: none;
  box-sizing: border-box;
  overflow: hidden;
}

.modal-title {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  padding: 15px 20px 15px;
  > div {
    width: 25px;
    height: 20px;
    text-align: right;
    line-height: 20px;
  }
}
.modal-content {
  padding: 10px 20px 20px;
}

.modal-bottom {
  padding: 0px 20px 20px 20px;
  display: flex;
  justify-content: flex-end;
}

.modal-bg-show {
  pointer-events: auto;
  opacity: 1;
}
.modal-show {
  transform: translate(-50%, -60%) scale(1);
  pointer-events: auto;
  opacity: 1;
}

// 按钮
.modal-bottom button {
  height: 35px;
  width: 100px;
  line-height: 35px;
  border-radius: 5px;
  margin-left: 10px;
  border: none;
}
.modal-bottom button:nth-child(2) {
  background: #acc2ff;
  color: #edf3ff;
}
.modal-bottom button:hover {
  opacity: 0.5;
}
</style>
