/*
 * @Author: dongping 3065975706@qq.com
 * @Date: 2022-06-27 09:53:41
 * @LastEditors: dongping 3065975706@qq.com
 * @LastEditTime: 2022-08-17 14:43:10
 * @FilePath: \blog-admind:\NEW-MY-PROJECT\my-doc\src\http-api\home.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  // 获取首页列表
  getCustomerLists: {
    url: "/admin/getCustomerLists",
    auth: true,
    method: "GET",
  },
  // 查询受生债
  findParticularYearList: {
    url: "/admin/findParticularYearList",
    auth: true,
    method: "GET",
  },
  // 添加受生债
  addParticularYearList: {
    url: "/admin/addParticularYearList",
    auth: true,
    method: "POST",
  },
  // 编辑受生债
  editParticularYear: {
    url: "/admin/editParticularYear",
    auth: true,
    method: "POST",
  },
  // 删除 受生债
  deleteParticularYear: {
    url: "/admin/deleteParticularYear",
    auth: true,
    method: "POST",
  },
  // 获取 账号管理
  getAccountNumberList: {
    url: "/admin/getAccountNumberList",
    auth: true,
    method: "GET",
  },
  // 添加 账号管理
  addAccountNumber: {
    url: "/admin/addAccountNumber",
    auth: true,
    method: "POST",
  },
  // 编辑 账号管理
  editccountNumber: {
    url: "/admin/editccountNumber",
    auth: true,
    method: "POST",
  },
  // 删除 账号管理
  deleteAccountNumber: {
    url: "/admin/deleteAccountNumber",
    auth: true,
    method: "POST",
  },
  // 获取统计数据
  getStatisticsData: {
    url: "/admin/getStatisticsData",
    auth: true,
    method: "GET",
  },
};


